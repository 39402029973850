import React from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";

import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import Person3Icon from "@mui/icons-material/Person3";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface CardImageProps {
  image?: any;
  alignment: boolean;
  containerWidth?: string;
  containerHeight?: string;
  imageWidth?: string;
  imageHeight?: string;
  title: string;
  description: string;
  direction?: string;
  containerSize: number;
  iconType?: string;
  detailDirection?: Boolean;
  detailLocation?: Boolean;
  detailPosition?: Boolean;
}

const CardImage = (prop: CardImageProps) => {
  const {
    image,
    alignment,
    containerWidth,
    containerHeight,
    imageWidth,
    imageHeight,
    title,
    description,
    direction,
    containerSize,
    iconType,
    detailDirection,
    detailLocation,
    detailPosition,
  } = prop;

  const search = () => {
    if (iconType) {
    } else {
      console.log("implement search and navigate to listing");
    }
  };

  return (
    <Grid
      item
      xs={containerSize}
      sx={{
        display: "flex",
        height: containerHeight ? containerHeight : "100%",
        width: containerWidth ? containerWidth : "100%",
      }}
      onClick={() => search()}
    >
      {alignment ? (
        <Grid
          sx={{
            display: "flex",
            alignItems: detailLocation ? "flex-start" : "flex-end",
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            borderRadius: 2,
            p: 3,
          }}
        >
          <Grid>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              {title}
            </Typography>
            <Typography sx={{ color: "white" }}>{description}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          sx={{
            display: "flex",
            flexDirection: direction ? direction : "column",
            width: "100%",
          }}
        >
          {image ? (
            <Grid
              item
              sx={{
                display: "flex",
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: imageWidth ? imageWidth : "100%",
                height: imageHeight ? imageHeight : "100vh",
                borderRadius: 2,
              }}
            ></Grid>
          ) : (
            <Grid
              item
              sx={{
                display: "flex",
                width: imageWidth ? imageWidth : "100%",
                height: imageHeight ? imageHeight : "100vh",
                borderRadius: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                type="button"
                sx={{ backgroundColor: "#7EC8E3", width: 100, height: 100 }}
                aria-label="search"
                color="primary"
                size={"large"}
              >
                {iconType === "person" && (
                  <PersonIcon
                    sx={{
                      color: "white",
                      backgroundColor: "transparent",
                      width: 70,
                      height: 70,
                    }}
                  />
                )}
                {iconType === "owner" && (
                  <Person3Icon sx={{ color: "white", width: 70, height: 70 }} />
                )}
                {iconType === "payment" && (
                  <PaymentIcon sx={{ color: "white", width: 70, height: 70 }} />
                )}
                {iconType === "fee" && (
                  <AttachMoneyIcon
                    sx={{ color: "white", width: 70, height: 70 }}
                  />
                )}
              </IconButton>
            </Grid>
          )}
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: detailDirection ? "column" : "row",
              justifyContent: detailPosition ? "center" : "",
              alignItems: detailPosition ? "center" : "",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: detailPosition ? "center" : "",
              }}
            >
              {title}
            </Typography>
            <Typography sx={{ textAlign: detailPosition ? "center" : "" }}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CardImage;
