import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  setSelectedPropertiesType,
  setSelectedPrices,
} from "../../../store/index";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/hooks";

interface PropertyFilterProps {
  title: string;
  selections: any[];
}

const PropertyFilter = (prop: PropertyFilterProps) => {
  const { title, selections } = prop;

  const [selectedSections, setSelectedSections] = useState<any[]>([]);

  // console.log("selections", selections);

  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selection: any
  ) => {
    let current_selected = [...selectedSections];
    console.log("selection", selection);

    if (event.target.checked) {
      current_selected.push(selection);
      setSelectedSections(current_selected);
    } else {
      current_selected = current_selected.filter((element: any) => {
        return element.label !== event.target.name;
      });
      setSelectedSections(current_selected);
    }
    if (title.includes("Property")) {
      dispatch(setSelectedPropertiesType(current_selected));
    } else if (title.includes("Prices")) {
      dispatch(setSelectedPrices(current_selected));
    }
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Grid item sx={{ backgroundColor: "grey" }}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item>
        <FormGroup>
          {selections &&
            selections?.length > 0 &&
            selections.map((selection, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      key={index}
                      checked={selection.active}
                      onChange={(event) => handleChange(event, selection)}
                      name={selection.label}
                    />
                  }
                  label={selection.label}
                />
              );
            })}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default PropertyFilter;
