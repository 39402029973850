import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";

const Messages = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, error, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        height: "90vh",
        // border: "1px solid red",
      }}
    >
      <AccountDrawer />
      <Grid component="main" sx={{ flexGrow: 1, p: 3 }}>
        Messages comming soon
      </Grid>
    </Grid>
  );
};

export default Messages;
