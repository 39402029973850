import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

interface LoginRequiredDialogProps {
  open: boolean;
  handleClose: () => void;
}

const LoginRequiredDialog = (props: LoginRequiredDialogProps) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  return (
    <Dialog open={open} maxWidth="md">
      <Grid container style={{ backgroundColor: "blue" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Login Required
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontSize: "18px" }}>
            {" "}
            Login is required in order to proceeed to the next step. Please
            login or register in order to complete your reservation
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Button
          onClick={() => navigate("/register")}
          style={{}}
          variant="contained"
        >
          register
        </Button>
        <Button
          autoFocus
          style={{}}
          variant="contained"
          onClick={() => navigate("/login")}
        >
          login
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LoginRequiredDialog;
