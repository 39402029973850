import AcUnitIcon from "@mui/icons-material/AcUnit";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import AirIcon from "@mui/icons-material/Air";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AttractionsIcon from "@mui/icons-material/Attractions";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import CribIcon from "@mui/icons-material/Crib";
import YardIcon from "@mui/icons-material/Yard";
import BalconyIcon from "@mui/icons-material/Balcony";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import BathtubIcon from "@mui/icons-material/Bathtub";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import WavesIcon from "@mui/icons-material/Waves";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PoolIcon from "@mui/icons-material/Pool";
import WineBarIcon from "@mui/icons-material/WineBar";
import TabUnselectedIcon from "@mui/icons-material/TabUnselected";
import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import AccessibleIcon from "@mui/icons-material/Accessible";
import WaterIcon from "@mui/icons-material/Water";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import BlenderIcon from "@mui/icons-material/Blender";
import CasinoIcon from "@mui/icons-material/Casino";
import SoapIcon from "@mui/icons-material/Soap";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import TimerIcon from "@mui/icons-material/Timer";
import AlbumIcon from "@mui/icons-material/Album";
import CheckIcon from "@mui/icons-material/Check";
import ToysIcon from "@mui/icons-material/Toys";
import NoStrollerIcon from "@mui/icons-material/NoStroller";
import MovieIcon from "@mui/icons-material/Movie";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import CoffeeIcon from "@mui/icons-material/Coffee";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import ComputerIcon from "@mui/icons-material/Computer";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import FlatwareIcon from "@mui/icons-material/Flatware";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import DeskIcon from "@mui/icons-material/Desk";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import DeckIcon from "@mui/icons-material/Deck";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ElevatorIcon from "@mui/icons-material/Elevator";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CableIcon from "@mui/icons-material/Cable";
import EvStationIcon from "@mui/icons-material/EvStation";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import BedIcon from "@mui/icons-material/Bed";
import FenceIcon from "@mui/icons-material/Fence";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import KitchenIcon from "@mui/icons-material/Kitchen";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import ExtensionIcon from "@mui/icons-material/Extension";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import DryIcon from "@mui/icons-material/Dry";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import HvacIcon from "@mui/icons-material/Hvac";
import HotTubIcon from "@mui/icons-material/HotTub";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";

export const backendURL =
  process.env.NODE_ENV !== "production" ? "http://localhost:5003" : "";

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const dbKeyConverterToLabel = (value: string) => {
  return value
    .replaceAll("0", " ")
    .replaceAll("1", "/")
    .replaceAll("3", "(")
    .replaceAll("5", ")")
    .replaceAll("7", "&");
};

export const specificationsIcons = {
  a1c: AcUnitIcon,
  air0conditioning: AcUnitIcon,
  air0filter: AirIcon,
  alarm0system: AddAlertIcon,
  allow0children: FamilyRestroomIcon,
  allow0pets: PetsIcon,
  allows0smoking: SmokingRoomsIcon,
  amenities: AttractionsIcon,
  baby0bath: BabyChangingStationIcon,
  baby0high0chair: EventSeatIcon,
  baby0monitor: ChildCareIcon,
  baby0travel0bed: BedroomBabyIcon,
  babysitter0recommendations: CribIcon,
  backyard: YardIcon,
  baking0sheet: AcUnitIcon,
  balcony: BalconyIcon,
  barbecue0utensils: RestaurantIcon,
  basketball0court: SportsBasketballIcon,
  bathtub: BathtubIcon,
  bbq0grill: OutdoorGrillIcon,
  beach: WavesIcon,
  beach0access: BeachAccessIcon,
  beach0essentials: BeachAccessIcon,
  beach0front: WavesIcon,
  beach0view: WavesIcon,
  bed0linens: AcUnitIcon,
  bidet: AcUnitIcon,
  bikes: BikeScooterIcon,
  blender: BlenderIcon,
  board0games: CasinoIcon,
  body0soap: SoapIcon,
  bread0maker: BreakfastDiningIcon,
  breakfast1coffee1tea: FreeBreakfastIcon,
  buzzer: AccessAlarmIcon,
  carbon0monoxide0alarm: TimerIcon,
  carbon0monoxide0detector: TimerIcon,
  cd1dvd0player: AlbumIcon,
  ceiling0fan: AcUnitIcon,
  changing0table: BabyChangingStationIcon,
  checkin0240hour: CheckIcon,
  childcare: ChildCareIcon,
  children0books7toys: ToysIcon,
  children0dinnerware: ToysIcon,
  children0not0allowed: NoStrollerIcon,
  cinema0room: MovieIcon,
  cleaning0available0during0stay: CleaningServicesIcon,
  cleaning0products: CleanHandsIcon,
  coffee: CoffeeIcon,
  coffee0maker: CoffeeMakerIcon,
  communal0pool: PoolIcon,
  computer0monitor: ComputerIcon,
  concierge: RoomServiceIcon,
  conditioner: AcUnitIcon,
  contactless0checkin: HowToRegIcon,
  cooking0basics: MicrowaveIcon,
  crockery0and0cutlery: FlatwareIcon,
  deadbolt0lock: NoEncryptionIcon,
  dehumidifier: AcUnitIcon,
  desk: DeskIcon,
  desk0chair: ChairAltIcon,
  dining0table: DeckIcon,
  dishes0and0silverware: FlatwareIcon,
  dishwasher: AcUnitIcon,
  doorman: MeetingRoomIcon,
  downtown: LocationCityIcon,
  drawers: AcUnitIcon,
  dryer: LocalLaundryServiceIcon,
  drying0rack0for0clothing: LocalLaundryServiceIcon,
  elevator: ElevatorIcon,
  emergency0exit: MedicalServicesIcon,
  enhanced0cleaning: CleaningServicesIcon,
  essentials: AcUnitIcon,
  ethernet0connection: CableIcon,
  ev0car0charger: EvStationIcon,
  event0friendly: CelebrationIcon,
  exercise0equipment: FitnessCenterIcon,
  extra0pillows0and0blankets: BedIcon,
  family0friendly: FamilyRestroomIcon,
  fenced0pool: FenceIcon,
  fenced0yard: FenceIcon,
  fire0extinguisher: FireExtinguisherIcon,
  fire0pit: WhatshotIcon,
  fireplace0guards: AcUnitIcon,
  first0aid0kit: FireplaceIcon,
  free0parking03on0premises5: LocalParkingIcon,
  free0parking03on0street5: LocalParkingIcon,
  freezer: KitchenIcon,
  fridge: KitchenIcon,
  game0console: VideogameAssetIcon,
  garden0deck: ExtensionIcon,
  gated0property: AssistantDirectionIcon,
  golf0course0front: GolfCourseIcon,
  golf0course0view: GolfCourseIcon,
  gym: FitnessCenterIcon,
  hair0dryer: DryIcon,
  hammock: AcUnitIcon,
  hangers: DryCleaningIcon,
  has0cat: PetsIcon,
  has0dog: PetsIcon,
  has0other0pet: PetsIcon,
  heated0pool: PoolIcon,
  heating: HvacIcon,
  heating03central0air5: HvacIcon,
  helipad: AcUnitIcon,
  hot0tub: HotTubIcon,
  hot0water: LocalDrinkIcon,
  hot0water0kettle: LocalDrinkIcon,
  in0person0checkin: HowToRegIcon,
  indoor0fireplace: AcUnitIcon,
  indoor0pool: AcUnitIcon,
  indoor0pool03seasonal5: AcUnitIcon,
  indoor0pool03year0round5: AcUnitIcon,
  inens0provided: AcUnitIcon,
  infant0not0allowed: AcUnitIcon,
  invoices0issued0on0request: AcUnitIcon,
  ipod0station: AcUnitIcon,
  iron: AcUnitIcon,
  ironing0facilities: AcUnitIcon,
  jacuzzi: AcUnitIcon,
  kayak: AcUnitIcon,
  kitchen: AcUnitIcon,
  kitchen0island: AcUnitIcon,
  kitchenette: AcUnitIcon,
  lake: AcUnitIcon,
  lake0access: AcUnitIcon,
  lake0front: AcUnitIcon,
  lake0view: AcUnitIcon,
  laptop: AcUnitIcon,
  laundromat0nearby: AcUnitIcon,
  library: AcUnitIcon,
  lock0on0bedroom: AcUnitIcon,
  long0term0stays0allowed: AcUnitIcon,
  luggage0droppoff0allowed: AcUnitIcon,
  massage0room: AcUnitIcon,
  meal0delivery: AcUnitIcon,
  microwave: AcUnitIcon,
  mini0fridge: AcUnitIcon,
  mosquito0net: AcUnitIcon,
  mountain: AcUnitIcon,
  mountain0view: AcUnitIcon,
  near0ocean: AcUnitIcon,
  ocean0front: AcUnitIcon,
  office1desk: AcUnitIcon,
  outdoor0dining0area: AcUnitIcon,
  outdoor0furniture: AcUnitIcon,
  outdoor0kitchen: AcUnitIcon,
  outdoor0lighting: AcUnitIcon,
  outdoor0play0area: AcUnitIcon,
  outdoor0seating: AcUnitIcon,
  outlet0covers: AcUnitIcon,
  oven: AcUnitIcon,
  pack0n0play0travel0crib: AcUnitIcon,
  paid0parking03Off0premises5: AcUnitIcon,
  paid0parking03On0premises5: AcUnitIcon,
  pans: AcUnitIcon,
  patio: AcUnitIcon,
  piano: AcUnitIcon,
  ping0pong: AcUnitIcon,
  pool: PoolIcon,
  pool0table: AcUnitIcon,
  pool03seasonal5: AcUnitIcon,
  pool05year0round5: AcUnitIcon,
  pots: AcUnitIcon,
  printer: AcUnitIcon,
  private0entrance: AcUnitIcon,
  property_id: AcUnitIcon,
  rain0shower: AcUnitIcon,
  record0player: AcUnitIcon,
  refridgerator: AcUnitIcon,
  resort: AcUnitIcon,
  rice0maker: AcUnitIcon,
  river: AcUnitIcon,
  room0darkening0shades: AcUnitIcon,
  rrsort0access: AcUnitIcon,
  ruralTown: AcUnitIcon,
  safe: AcUnitIcon,
  safe0box: AcUnitIcon,
  safety0card: AcUnitIcon,
  sea0view: AcUnitIcon,
  shampoo: AcUnitIcon,
  shower0gel: AcUnitIcon,
  single0level0home: AcUnitIcon,
  ski0in1ski0out: AcUnitIcon,
  smoke0alarm: AcUnitIcon,
  smoke0detector: AcUnitIcon,
  soccer0field: AcUnitIcon,
  sound0system: AcUnitIcon,
  stair0gates: AcUnitIcon,
  steam0room: AcUnitIcon,
  stove: AcUnitIcon,
  surveillance: AcUnitIcon,
  table0corner0guards: AcUnitIcon,
  tennis: AcUnitIcon,
  toaster: AcUnitIcon,
  toiletries: AcUnitIcon,
  towels0provided: AcUnitIcon,
  trash0compactor: AcUnitIcon,
  tv: AcUnitIcon,
  uses0disinfectants: AcUnitIcon,
  uses0disinfectants0for0high0touch0surfaces: AcUnitIcon,
  utensils: AcUnitIcon,
  ventilation0fan: AcUnitIcon,
  village: AcUnitIcon,
  washer: LocalLaundryServiceIcon,
  washer1dryer0Shared: LocalLaundryServiceIcon,
  water0front: WaterIcon,
  water0kettle: KitesurfingIcon,
  water0view: WaterIcon,
  wheelchair0accessible: AccessibleIcon,
  wifi: SignalWifiStatusbar4BarIcon,
  window0guards: TabUnselectedIcon,
  wine0cellar: AcUnitIcon,
  wine0glasses: WineBarIcon,
};
