import React, { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

const LayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  //   gap: theme.spacing(0.5),
  //   padding: theme.spacing(3, 4),
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: theme.shape.borderRadius,
  //   boxShadow: theme.shadows[2],
  //   letterSpacing: "-0.025em",
  //   fontWeight: 600,
}));

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <LayoutContainer>
      <Outlet />
    </LayoutContainer>
  );
};

export default Layout;
