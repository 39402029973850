import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  registerUser,
  verifyEmail,
} from "../../thunks/authenticationThunk";

interface AuthenticationState {
  loading: Boolean;
  userInfo: object | null;
  userToken: string | null;
  error: any;
  response: any;
  success: Boolean;
  loggedIn: Boolean;
  previousLocation: string;
}
const initialState: AuthenticationState = {
  loading: false,
  userInfo: {},
  loggedIn: false,
  userToken: null,
  error: null,
  response: null,
  success: false,
  previousLocation: "",
};
export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.loggedIn = false;
      state.success = false;
    },
    reset: (state) => {
      state.error = null;
      state.success = false;
      state.response = null;
    },
    setPreviousLocation: (state, action) => {
      state.previousLocation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.loggedIn = false;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.userToken = action.payload.accessToken;
      state.userInfo = action.payload.userInfo;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.loggedIn = false;
      state.error = action.payload;
    });
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.response = action.payload;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { logout, reset, setPreviousLocation } =
  authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
