import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/commonComponents/header/header";
import Footer from "./components/commonComponents/footer/footer";
import Layout from "./components/commonComponents/layout/layout";
import Home from "./pages/homePage/home";
import VacationRental from "./pages/vacationRentalPage/vacationRental";
import Company from "./pages/companyPage/company";
import Shopping from "./pages/shoopingPage/shooping";
import Listings from "./pages/listingsPage/listings";
import Login from "./pages/login/login";
import {
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import Register from "./pages/register/register";
import EmailVerification from "./pages/emailVerificationPage/emailVerification";
import "./App.css";
import ListingDetails from "./pages/listingDetailPage/listingDetail";
import Account from "./pages/dashboardPage/dashboard";
import Messages from "./pages/messagesPage/messages";
import Calendar from "./pages/calendar/calendar";
import Reports from "./pages/reports/reports";
import Settings from "./pages/settings/settings";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid sx={{ minWidth: "1150px" }}>
        <ElevationScroll>
          <Header />
        </ElevationScroll>
        <Toolbar />
        <Grid>
          <Grid>
            <Routes>
              <Route path="/" element={<Layout children={""} />}>
                <Route index element={<Home />} />
                <Route
                  path="/management/vacationrental"
                  element={<VacationRental />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/company" element={<Company />} />
                <Route path="/shooping" element={<Shopping />} />
                <Route path="/listings" element={<Listings />} />
                <Route
                  path="/listings/:propertyId"
                  element={<ListingDetails />}
                />
                <Route
                  path="/emailVerification/:token"
                  element={<EmailVerification />}
                />
                <Route path="/dashboard" element={<Account />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/management" element={<Account />} />
              </Route>
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default App;
