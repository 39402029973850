import { createSlice } from "@reduxjs/toolkit";
import { Property } from "../../../types/propertiesTypes";
import {
  getAllPropertyTypes,
  getPropertySpecification,
  getPropertyType,
  getPropertiesListings,
} from "../../thunks/listingsThunk";
import dayjs from "dayjs";

interface PropertyDetails {
  property: string;
}

interface ListingsState {
  propertyLoading: Boolean;
  allProperties: any[] | null;
  allPropertyTypes: any[] | null;
  selectedPropertyDetails: Property;
  allDestination: any[];

  currentDestination?: object | null;

  selectedPropertyName?: string;
  selectedPropertyType?: object[];
  selectedPrice?: object[];
  selectedAmenities?: object[];

  error: any;
  response: any;
  success: Boolean;
}
const initialState: ListingsState = {
  propertyLoading: false,
  allProperties: [],
  allPropertyTypes: [],
  allDestination: [],
  selectedPropertyType: [],
  selectedPrice: [],
  selectedPropertyDetails: {
    property_id: "",
    property_name: "",
    guests: "",
    description: "",
    instant_booking: true,
    rooms: "",
    extra: "",
    rules: "",
    bedrooms: "",
    bathrooms: "",
    property_images_url: "",
  },
  currentDestination: {
    destination: "Anywhere",
    value: null,
    guests: 2,
    checkIn: dayjs(new Date()),
    checkOut: dayjs(new Date()),
  },
  error: null,
  response: null,
  success: false,
};
export const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    setSelectedProperty: (state, action) => {
      state.selectedPropertyDetails = {
        ...state.selectedPropertyDetails,
        ...action.payload,
      };
    },
    setCurrentDestination: (state, action) => {
      console.log("setSelectedProperty action", action.payload);
      state.currentDestination = {
        ...state.currentDestination,
        ...action.payload,
      };
    },
    setSelectedPropertiesType: (state, action) => {
      console.log("setSelectedProperty action", action.payload);
      state.selectedPropertyType = action.payload;
    },
    setSelectedPrices: (state, action) => {
      console.log("setSelectedProperty action", action.payload);
      state.selectedPrice = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPropertiesListings.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getPropertiesListings.fulfilled, (state, action) => {
      console.log("getPropertiesListings fufil action.payload", action.payload);
      state.allProperties = action.payload;
      state.propertyLoading = false;
    });
    builder.addCase(getPropertiesListings.rejected, (state, action) => {
      console.log(
        "getPropertiesListings not fufil action.payload",
        action.payload
      );
      state.error = action.payload;
      state.propertyLoading = false;
    });

    builder.addCase(getAllPropertyTypes.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getAllPropertyTypes.fulfilled, (state, action) => {
      console.log("getAllPropertyTypes fufil action.payload", action.payload);
      state.allPropertyTypes = action.payload;
      state.propertyLoading = false;
    });
    builder.addCase(getAllPropertyTypes.rejected, (state, action) => {
      console.log(
        "getAllPropertyTypes not fufil action.payload",
        action.payload
      );
      state.propertyLoading = false;
    });

    builder.addCase(getPropertyType.pending, (state) => {});
    builder.addCase(getPropertyType.fulfilled, (state, action) => {});
    builder.addCase(getPropertyType.rejected, (state, action) => {});

    builder.addCase(getPropertySpecification.pending, (state) => {
      state.propertyLoading = true;
      state.error = null;
    });
    builder.addCase(getPropertySpecification.fulfilled, (state, action) => {
      console.log(
        "getPropertySpecification fufil action.payload",
        action.payload
      );
      state.selectedPropertyDetails = {
        ...state.selectedPropertyDetails,
        propertySpecifications: action.payload,
      };
      state.propertyLoading = false;
    });
    builder.addCase(getPropertySpecification.rejected, (state, action) => {
      console.log(
        "getPropertySpecification not fufil action.payload",
        action.payload
      );
      state.error = action.payload;
      state.propertyLoading = false;
    });

    // builder.addCase(loginUser.pending, (state) => {});
    // builder.addCase(loginUser.fulfilled, (state, action) => {});
    // builder.addCase(loginUser.rejected, (state, action) => {});
  },
});

export const {
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
} = listingsSlice.actions;

export const listingsReducer = listingsSlice.reducer;
