import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerInfo } from "../../types/formsTypes";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerUser } from "../../store/thunks/authenticationThunk";
import logo from "../../assets/logo_transparent.png";
import GoogleLogin from "react-google-login";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { reset } from "../../store";
import Footer from "../../components/commonComponents/footer/footer";

const Register = () => {
  const { loading, error, loggedIn, response, success } = useAppSelector(
    (state: any) => state.authentication
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [navigate, loggedIn]);

  const validateLogin = Yup.object({
    first_name: Yup.string().required("Fist Name is Required"),
    last_name: Yup.string().required("Last name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  let registerInitialValues: registerInfo = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const registerForm = useFormik({
    initialValues: registerInitialValues,
    validationSchema: validateLogin,
    onSubmit: (values: registerInfo) => {
      dispatch(registerUser(values));
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={loading} />
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "stretch",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              alignContent: "stretch",
            }}
          >
            <Grid item xs={6} sx={{ padding: 3 }}>
              <form onSubmit={registerForm.handleSubmit}>
                <Grid
                  container
                  sx={{
                    gap: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" sx={{}}>
                      Create an Account
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="First Name"
                      label="First Name"
                      id="first_name"
                      name="first_name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.first_name}
                      error={
                        registerForm.touched.first_name &&
                        Boolean(registerForm.errors.first_name)
                      }
                      helperText={registerForm.errors.first_name}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Last Name"
                      name="last_name"
                      label="Last Name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.last_name}
                      error={
                        registerForm.touched.last_name &&
                        Boolean(registerForm.errors.last_name)
                      }
                      helperText={registerForm.errors.last_name}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Email"
                      name="email"
                      variant="filled"
                      label="Email"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.email}
                      error={
                        registerForm.touched.email &&
                        Boolean(registerForm.errors.email)
                      }
                      helperText={registerForm.errors.email}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <Typography>Password</Typography>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      label=""
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                      name="password"
                      fullWidth
                      sx={{}}
                      value={registerForm.values.password}
                      error={
                        registerForm.touched.password &&
                        Boolean(registerForm.errors.password)
                      }
                      color={"primary"}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  {error && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "red",
                      }}
                    ></Grid>
                  )}
                  <Grid item xs={12} sx={{}}>
                    <Button
                      variant="contained"
                      sx={{ width: "100%", backgroundColor: "#000000" }}
                      onClick={() => registerForm.submitForm}
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{}}></Grid>
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CBF6FF",
                height: "100%",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ maxHeight: "300px" }} src={logo} alt={"Test"} />
              </Grid>
            </Grid>
          </Grid>
          {success && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                color: "black",
                padding: "20px",
              }}
            >
              {response?.message}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};
export default Register;
