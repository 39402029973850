import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";

const Account = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { previousLocation, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    if (previousLocation && previousLocation !== "") {
      navigate(previousLocation);
    }
  }, [navigate, loggedIn]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        height: "90vh",
        // border: "1px solid red",
      }}
    >
      <AccountDrawer />
      <Grid component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
              marginBottom: "10%",
            }}
          >
            Good Morning, {userInfo.first_name}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
              marginBottom: "10%",
            }}
          >
            Upcomming Reservations
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
              marginBottom: "10%",
            }}
          >
            Upgrade Your Rental
          </Typography>
        </Grid>
        <Grid
          color="transparent"
          sx={{
            backgroundColor: "white",
            width: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          position="fixed"
        ></Grid>
      </Grid>
    </Grid>
  );
};

export default Account;
