import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setSelectedProperty } from "../../../store/index";
import StarIcon from "@mui/icons-material/Star";
import { getPropertySpecifications } from "../../../apis/listingsApis";
import { dbKeyConverterToLabel } from "../../../utilities/utils";
import { PropertySpecifications } from "../../../types/propertiesTypes";
import dayjs from "dayjs";
import { string } from "yup";

interface SearchCardProps {
  propertyDetails: any;
}

const SearchCard = (prop: SearchCardProps) => {
  const { propertyDetails } = prop;
  const { currentDestination } = useAppSelector((state: any) => state.listings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // console.log("propertyDetails", propertyDetails.property_id);
  const [propertySpecifications, setPropertySpecifications] =
    useState<PropertySpecifications>({});
  const [propertySpecificationsView, setPropertySpecificationsView] =
    useState("");

  const [pricePerDay, setPricePerDay] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    getPropertySpecificationsView();
  }, []);

  useEffect(() => {
    setPricingDetails();
  }, [currentDestination]);

  const setPricingDetails = () => {
    let checkIn =
      typeof currentDestination?.checkIn === "string"
        ? dayjs(new Date(currentDestination?.checkIn))
        : currentDestination?.checkIn;
    let checkOut =
      typeof currentDestination?.checkOut === "string"
        ? dayjs(new Date(currentDestination?.checkOut))
        : currentDestination?.checkOut;

    const number_of_days =
      currentDestination?.checkOut && currentDestination?.checkIn
        ? Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24))
        : 1;
    const temp_price_per_day = propertyDetails.price_per_day
      ? Math.ceil(propertyDetails.price_per_day * 1.033)
      : 0;
    const price_nights_total = propertyDetails.price_per_day
      ? Math.ceil(temp_price_per_day * 1.078 * number_of_days)
      : 0;
    const temp_total_price = price_nights_total
      ? price_nights_total + Number(propertyDetails.cleaning_fee)
      : 0;
    const price_with_taxes = propertyDetails.price_per_day
      ? Math.ceil(temp_total_price * 1.078)
      : 0;

    console.log("temp_total_price", temp_total_price);
    setPricePerDay(Math.round(temp_price_per_day * 100) / 100);
    setTotalPrice(Math.round(price_with_taxes * 100) / 100);
  };

  const handleSelectListing = (propertyDetails: any) => {
    let tempSelectedPorpertyDetails = {
      ...propertyDetails,
      propertySpecifications: propertySpecifications,
    };
    dispatch(setSelectedProperty(tempSelectedPorpertyDetails));
    navigate("/listings/" + propertyDetails.property_id);
  };

  const getPropertySpecificationsView = async () => {
    const tempSpec = [];
    const propertySpecs = await getPropertySpecifications(
      propertyDetails.property_id
    );

    if (propertySpecs?.property_specifications?.length > 0) {
      setPropertySpecifications(propertySpecs.property_specifications[0]);
      let specs = propertySpecs.property_specifications[0];
      let specsKeys = Object.keys(specs).sort(() => 0.5 - Math.random());

      for (const key of specsKeys) {
        // console.log(`${key}: ${specs[key]}`);
        if (specs[key] === true) {
          tempSpec.push(dbKeyConverterToLabel(key));
        }
        if (tempSpec.length === 3) {
          break;
        }
      }
      setPropertySpecificationsView(tempSpec.join(" * "));
    }
  };
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        margin: 2,
        borderRadius: "10px",
      }}
    >
      <AwesomeSlider bullets={false} style={{ width: "450px" }}>
        {propertyDetails?.property_images_url
          .split(",")
          .map((image_url: string) => {
            return (
              <div>
                <img src={image_url} />
              </div>
            );
          })}
      </AwesomeSlider>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          "&:hover": {
            border: "1px solid blue",
          },
        }}
        onClick={() => handleSelectListing(propertyDetails)}
      >
        <Grid item>
          <Typography sx={{ fontSize: 12 }}>
            {propertyDetails.property_type_label} in {propertyDetails.city}
            {", "}
            {propertyDetails.state
              ? propertyDetails.state
              : propertyDetails.country}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>
            {propertyDetails.property_name}
          </Typography>
        </Grid>
        <Divider />
        <Grid item>
          <Grid item xs={12}>
            <Typography sx={{}}>
              {propertyDetails.guests} guests * {propertyDetails.bedrooms} beds
              * {propertyDetails.bathrooms} bathrooms
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textTransform: "capitalize" }}>
            <Typography>{propertySpecificationsView}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              alignSelf: "flex-end",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <StarIcon color="primary" sx={{}} />
            {propertyDetails.review ? propertyDetails.review : 5.0} (325
            reviews)
          </Grid>
          <Grid
            item
            sx={{
              alignSelf: "flex-end",
            }}
          >
            <Typography sx={{ fontSize: 12 }}>$ {totalPrice} total</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              $ {pricePerDay} /Night
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchCard;
