import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { reset } from "../../store";
import { useParams } from "react-router-dom";
import { verifyEmail } from "../../store/thunks/authenticationThunk";

const EmailVerification = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, loggedIn, response } = useAppSelector(
    (state: any) => state.authentication
  );
  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
    dispatch(reset());
    if (token && token !== "") {
      dispatch(verifyEmail({ verificationToken: token }));
    } else {
      navigate("/");
    }
    // dispatch(registerUser(values));
  }, [navigate, loggedIn]);
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      {response && (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant={"h6"}>{response?.message}</Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#000000" }}
            onClick={() => navigate("/login")}
            type="submit"
          >
            Login
          </Button>
        </Grid>
      )}
      {error && (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography>{error?.data?.message}</Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#000000" }}
            onClick={() => navigate("/login")}
            type="submit"
          >
            Login
          </Button>
          {" or "}
          <Button
            variant="contained"
            sx={{ backgroundColor: "#000000" }}
            onClick={() => navigate("/register")}
            type="submit"
          >
            Sign Up
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EmailVerification;
