import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const Footer = () => {
  const subscribeNewsletter = () => {};
  const [email, setEmail] = useState("");
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "	#F8F8F8",
      }}
    >
      <Grid sx={{ mx: "10%" }}>
        <Grid container sx={{ my: 2 }}>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Support
              </Typography>
              <Typography sx={{}}>Help Center</Typography>
              <Typography sx={{}}>Owner Help</Typography>
              <Typography sx={{}}>Renter Help</Typography>
              <Typography sx={{}}>Contact</Typography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Company
              </Typography>
              <Typography sx={{}}>About us</Typography>
              <Typography sx={{}}>Careers</Typography>
              <Typography sx={{}}>Investors</Typography>
              <Typography sx={{}}>Blog</Typography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5%",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product
              </Typography>
              <Typography sx={{}}>Management</Typography>
              <Typography sx={{}}>Realestate</Typography>
              <Typography sx={{}}>Search listings</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{}}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Subscribe to our newsletter
            </Typography>
            <Typography>For Announcements and exclusive deals</Typography>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                sx={{ paddingRight: "5px" }}
                placeholder="Input your email"
                inputProps={{}}
                fullWidth
                value={email}
                error={false}
                onChange={(val) => subscribeNewsletter()}
              />
              <Button variant="contained">Subscribe</Button>
            </Grid>
            <Typography sx={{ my: 3 }}>
              Provinding owners & investors a passive income stream while
              delivering a hotel-like quality stay for guests
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid
            item
            xs={9}
            sx={{ flexGrow: 1, display: "flex", flexDirection: "row", my: 2 }}
          >
            <Typography>@ 2004 LVLpm. * Privacy * Terms</Typography>
          </Grid>
          <Grid item xs={3} sx={{ my: 2 }}>
            USD{" "}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
