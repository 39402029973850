import Payment from "payment";
import valid from "card-validator";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: string, allValues: any = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data: any) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export function validatCard(values: any) {
  let errors: any = {};
  let creditCard: any = valid.number(values.cardNumber);

  creditCard.expirationDate = valid.expirationDate(values.cardExpiration);
  creditCard.cvv = valid.cvv(values.cardCvc);
  creditCard.cardholderName = valid.cardholderName(values.cardName);
  creditCard.postalCode = valid.postalCode(values.zipCode);

  errors.message = "An unknown error occured. Please try again later";
  errors.cardName = false;
  errors.cardNumber = false;
  errors.cardIssuer = false;
  errors.cardExpiration = false;
  errors.cardCvc = false;
  errors.zipCode = false;
  errors.isValid = false;

  console.log("validatCard creditCard", creditCard);
  console.log("validatCard values", values);

  if (values.zipCode === null || !values.zipCode.trim()) {
    errors.message = "Credit card postal code is not complete";
  } else if (creditCard.postalCode.isValid) {
    errors.zipCode = true;
  } else {
    errors.message = "Credit card postal code is invalid";
  }

  //Card CVV expiration
  if (values.cardCvc === null || !values.cardCvc.trim()) {
    errors.message = "Credit card CVC is not complete";
  } else if (creditCard.cvv.isValid) {
    errors.cardCvc = true;
  } else {
    errors.message = "Credit card CVC is invalid";
  }

  //Card Expiration Verification
  if (values.cardExpiration === null || !values.cardExpiration.trim()) {
    errors.message = "Credit card expiration date is not complete";
  } else if (creditCard.expirationDate.isValid) {
    errors.cardExpiration = true;
  } else {
    errors.message = "Credit card expiration date is invalid";
  }

  //Card Type Verification
  if (
    values.cardIssuer === null ||
    !values.cardIssuer.trim() ||
    creditCard.card === null
  ) {
    errors.message = "Credit card type is not complete";
  } else if (
    creditCard.card.type &&
    creditCard.card.type.toUpperCase() === values.cardIssuer.toUpperCase()
  ) {
    errors.cardIssuer = true;
  } else {
    errors.message = "Credit card type is invalid";
  }

  //Card Number Verification
  if (values.cardNumber === null || !values.cardNumber.trim()) {
    errors.message = "Credit card number is not complete";
  } else if (creditCard.isValid) {
    errors.cardNumber = true;
  } else {
    errors.message = "Credit card number is invalid";
  }

  //Cardholder Name Verification
  if (values.cardName === null || !values.cardName.trim()) {
    errors.message = "Cardholder name is not complete";
  } else if (creditCard.cardholderName.isValid) {
    errors.cardName = true;
  } else {
    errors.message = "Cardholder name is invalid";
  }

  if (
    errors.cardIssuer &&
    errors.cardName &&
    errors.cardNumber &&
    errors.cardExpiration &&
    errors.zipCode &&
    errors.cardCvc
  ) {
    errors.variant = "success";
    errors.message = "Credit Card is valid";
    errors.isValid = true;
  }

  return errors;
}
