import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../components/commonComponents/footer/footer";
import {
  dbKeyConverterToLabel,
  specificationsIcons,
} from "../../utilities/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { setCurrentDestination, setPreviousLocation } from "../../store";
import StripePayment from "../../components/reusableComponents/stripePayment/stripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDialog from "../../components/dialogComponents/paymentDialog/paymentDialog";
import LoginRequiredDialog from "../../components/dialogComponents/loginRequiredDialog/loginRequiredDialog";
const stripePromise = loadStripe(
  "pk_test_51NO2UgEC8YRyVLJi8TjlK60HOfzNYsZ1iDEBwDytWFqED5NKq3ot7WcNJn8chykMUkYUVgrIjD2hUuDGuqFhtZf2009do8353y"
);

const ListingDetails = () => {
  const { propertyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPropertyDetails, currentDestination } = useAppSelector(
    (state: any) => state.listings
  );
  const { loggedIn } = useAppSelector((state: any) => state.authentication);

  console.log("selectedPropertyDetails", selectedPropertyDetails);

  const [activeAmenities, setActiveAmenities] = useState<string[]>([]);
  const [allActiveAmenities, setAllActiveAmenities] = useState<string[]>([]);

  const [nights, setNights] = useState<any>(0);

  const [stayPrice, setStayPrice] = useState<any>(0);
  const [taxes, setTaxes] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);

  const [openDescription, setOpenDescription] = useState(false);
  const [openAmenities, setOpenAmenities] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openLoginRequiredDialog, setOpenLoginRequiredDialog] = useState(false);

  const handleOpenDescription = () => {
    setOpenDescription(true);
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
  };

  const handleOpenAmenities = () => {
    setOpenAmenities(true);
  };

  const handleCloseAmenities = () => {
    setOpenAmenities(false);
  };

  const activeAmenitiesView = () => {
    let tempActiveAmenities = [];
    setAllActiveAmenities(
      Object.keys(selectedPropertyDetails.propertySpecifications).filter(
        (key) => selectedPropertyDetails.propertySpecifications[key] === true
      )
    );

    let amenitiesKeys = Object.keys(
      selectedPropertyDetails.propertySpecifications
    ).sort(() => 0.5 - Math.random());

    for (const key of amenitiesKeys) {
      if (selectedPropertyDetails.propertySpecifications[key] === true) {
        if (tempActiveAmenities.length < 8) {
          tempActiveAmenities.push(key);
        }
      }
    }
    setActiveAmenities(tempActiveAmenities);
  };

  const reservationInitialValues = {
    guests: 2,
    checkIn: new Date(),
    checkOut: new Date().getDate() + 1,
    amount: 0,
    cardNumber: "",
    cardHolder: "",
    expidation: "",
    ccv: "",
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const validateReservation: any = Yup.object({
    guests: Yup.number().integer().min(1).max(10).required(),
    checkIn: Yup.date().min(today, "Please choose future date"),
    checkOut: Yup.date().when(
      "checkIn",
      (checkIn, schema) => checkIn && schema.min(checkIn)
    ),
    amount: Yup.number().integer().min(0).required(),
  });

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };
  const handleCloseLoginRequiredtDialog = () => {
    dispatch(setPreviousLocation(""));
    setOpenLoginRequiredDialog(false);
  };

  const reservationForm = useFormik({
    enableReinitialize: false,
    initialValues: reservationInitialValues,
    validationSchema: validateReservation,
    onSubmit: (values) => {
      console.log("reservationForm onSubmit", values);
      if (loggedIn) {
        setOpenPaymentDialog(true);
      } else {
        console.log("location", location.pathname);
        dispatch(setPreviousLocation(location.pathname));
        setOpenLoginRequiredDialog(true);
      }
    },
  });

  const setInitialPricing = () => {
    let checkIn =
      typeof currentDestination?.checkIn === "string"
        ? dayjs(new Date(currentDestination?.checkIn))
        : currentDestination?.checkIn;

    reservationForm.setFieldValue(
      "checkIn",
      checkIn?.toDate() >= today ? checkIn : today
    );
    let checkOut =
      typeof currentDestination?.checkOut === "string"
        ? dayjs(new Date(currentDestination?.checkOut))
        : currentDestination?.checkOut;

    reservationForm.setFieldValue(
      "checkOut",
      checkOut?.toDate() >= today.getDate() + 1 ? checkOut : today.getDate() + 1
    );
    let nights =
      currentDestination?.checkOut && currentDestination?.checkIn
        ? Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24))
        : 1;
    setNights(nights);
    const stay_price = Math.ceil(
      nights * selectedPropertyDetails.price_per_day * 1.033
    );
    const stay_price_with_cleaning_fee =
      stay_price + Number(selectedPropertyDetails.cleaning_fee);
    setStayPrice(Math.round(stay_price * 100) / 100);
    const taxes = stay_price_with_cleaning_fee * 0.078;
    setTaxes(Math.round(taxes * 100) / 100);
    const total_price = stay_price_with_cleaning_fee + taxes;
    setTotalPrice(Math.round(total_price * 100) / 100);
  };

  useEffect(() => {
    if (propertyId && propertyId !== selectedPropertyDetails.property_id) {
      navigate("/listings");
    } else {
      activeAmenitiesView();
      setInitialPricing();
    }
  }, [currentDestination]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Grid>
      <Grid sx={{ marginTop: "40px", mx: "10%" }}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Carousel
            containerClass="carousel-container"
            itemClass="carousel-item"
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={5000}
          >
            {selectedPropertyDetails.property_images_url
              .split(",")
              .map((image_url: string, index: number) => {
                return (
                  <div key={index}>
                    <img
                      src={image_url}
                      style={{ width: "450px", height: "200px" }}
                    />
                  </div>
                );
              })}
          </Carousel>
        </Grid>
        <Grid>
          <Grid sx={{ margin: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {selectedPropertyDetails.property_name}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {selectedPropertyDetails.city}
              {", "}
              {selectedPropertyDetails.state
                ? selectedPropertyDetails.state
                : selectedPropertyDetails.country}
            </Typography>
          </Grid>
          <Grid container sx={{ display: "flex", margin: "10px" }}>
            <Grid item xs={6} sx={{}}>
              <Grid sx={{}}>
                <Typography sx={{ fontWeight: "bold" }}>Overview</Typography>
              </Grid>
              <Grid sx={{ margin: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {selectedPropertyDetails.guests} guests *{" "}
                  {selectedPropertyDetails.bedrooms} beds *{" "}
                  {selectedPropertyDetails.bathrooms} bathrooms
                </Typography>
              </Grid>
              <Grid sx={{ margin: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Free Cancellation for 48 hours
                </Typography>
              </Grid>
              <Grid sx={{ margin: "10px" }}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {selectedPropertyDetails.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={5} sx={{}}>
              <Grid>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  This place offers
                </Typography>
              </Grid>
              <Grid container spacing={1} sx={{ margin: "10px" }}>
                {activeAmenities.map((value: string, index) => {
                  let AmenitiesIcon =
                    specificationsIcons[
                      value as keyof typeof specificationsIcons
                    ];
                  let label: string = dbKeyConverterToLabel(value);
                  return (
                    <Grid
                      item
                      xs={2}
                      sm={4}
                      md={6}
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      {AmenitiesIcon && <AmenitiesIcon />}
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {label}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ margin: "10px" }}>
              <Button onClick={handleOpenDescription}>Show more</Button>
            </Grid>
            <Grid item xs={5} sx={{ margin: "10px" }}>
              <Button onClick={handleOpenAmenities}>Show All Amenities</Button>
            </Grid>
          </Grid>
          <Grid container xs={12} sx={{ display: "flex", margin: "10px" }}>
            <Grid container xs={8} sx={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px",
                  // border: "1px solid red",
                }}
              >
                <Grid item>$ {87}/night</Grid>
                <Grid item> review</Grid>
              </Grid>
              <Divider sx={{ width: "100%" }}> </Divider>
              <Grid
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                }}
              >
                <Grid item xs={6} sx={{ margin: "0px 0px 0px 10px" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Check In
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="checkIn"
                      value={dayjs(reservationForm.values.checkIn)}
                      onChange={(value) => {
                        reservationForm.setFieldValue("checkIn", value);
                        dispatch(setCurrentDestination({ checkIn: value }));
                      }}
                      disablePast
                      views={["year", "month", "day"]}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sx={{ margin: "0px 10px 0px 0px" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Check Out
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="checkOut"
                      value={dayjs(reservationForm.values.checkOut)}
                      onChange={(value) => {
                        reservationForm.setFieldValue("checkOut", value);
                        dispatch(setCurrentDestination({ checkOut: value }));
                      }}
                      disablePast
                      views={["year", "month", "day"]}
                      sx={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid xs={12} item sx={{ display: "flex", margin: "10px" }}>
                <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Guests
                  </InputLabel>
                  <Select
                    name="guests"
                    id="guest"
                    value={reservationForm.values.guests}
                    onChange={(event) => {
                      reservationForm.setFieldValue(
                        "guests",
                        event.target.value
                      );
                      dispatch(
                        setCurrentDestination({ guests: event.target.value })
                      );
                    }}
                    label="Guests"
                    sx={{}}
                  >
                    {Array.from({ length: 10 }, (v, k) => k + 1).map((key) => {
                      return <MenuItem value={key}>{key}</MenuItem>;
                    })}
                    <MenuItem value={10}>{2} guests</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Grid>$86 x {nights} nights</Grid>
                <Grid>$ {stayPrice}</Grid>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Grid>Entire Stay Cleaning Fee</Grid>
                <Grid>${selectedPropertyDetails?.cleaning_fee}</Grid>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Grid>Taxes</Grid>
                <Grid>$ {taxes}</Grid>
              </Grid>
              <Grid
                container
                xs={12}
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Grid>Total (USD)</Grid>
                <Grid>$ {totalPrice}</Grid>
              </Grid>
              <Grid container xs={12} item>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "blue",
                    margin: "10px",
                  }}
                  onClick={() => reservationForm.submitForm()}
                >
                  Reserve
                </Button>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>You wont be charge yet</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      <Dialog
        open={openDescription}
        onClose={handleCloseDescription}
        aria-labelledby="description-dialog-title"
        aria-describedby="description-dialog"
      >
        <DialogTitle id="description-dialog-title">
          Property Description
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description-dialog">
            {selectedPropertyDetails.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDescription} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmenities}
        onClose={handleCloseAmenities}
        aria-labelledby="amenities-dialog-title"
        aria-describedby="amenities-dialog"
      >
        <DialogTitle id="amenities-dialog-title">
          Property Amenities
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} sx={{ margin: "10px" }}>
            {allActiveAmenities.map((value: string, index) => {
              let AmenitiesIcon =
                specificationsIcons[value as keyof typeof specificationsIcons];
              let label: string = dbKeyConverterToLabel(value);
              return (
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={3}
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  {AmenitiesIcon && <AmenitiesIcon />}
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAmenities} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <PaymentDialog
        open={openPaymentDialog}
        handleClose={handleClosePaymentDialog}
        nights={nights}
        taxes={taxes}
        stayPrice={stayPrice}
        cleaningFees={selectedPropertyDetails?.cleaning_fee}
        totalPrice={totalPrice}
      />
      <LoginRequiredDialog
        open={openLoginRequiredDialog}
        handleClose={handleCloseLoginRequiredtDialog}
      />
    </Grid>
  );
};

export default ListingDetails;
