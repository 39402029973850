import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  authenticationReducer,
  logout,
  reset,
  setPreviousLocation,
} from "./features/authentication/authenticationSlice";
import {
  listingsReducer,
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
} from "./features/listing/listingsSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  listings: listingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat();
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export {
  logout,
  reset,
  setPreviousLocation,
  setSelectedProperty,
  setCurrentDestination,
  setSelectedPropertiesType,
  setSelectedPrices,
};

export const persistor = persistStore(store);
