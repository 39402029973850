import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import SearchListing from "../../components/reusableComponents/searchListings/searchListings";
import PropertyFilter from "../../components/reusableComponents/propertyFilter/propertyFilter";
import SearchCard from "../../components/reusableComponents/searchCard/searchCard";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import {
  getAllPropertyTypes,
  getPropertiesListings,
} from "../../store/thunks/listingsThunk";
import ReactPaginate from "react-paginate";
import Footer from "../../components/commonComponents/footer/footer";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { prices } from "../../utilities/constants";

const Listings = () => {
  const {
    propertyLoading,
    allProperties,
    allPropertyTypes,
    currentDestination,
    selectedPropertyName,
    selectedPropertyType,
    selectedPrice,
  } = useAppSelector((state: any) => state.listings);
  const dispatch = useAppDispatch();

  // console.log("allPropertyTypes", allPropertyTypes);

  useEffect(() => {
    dispatch(getPropertiesListings({ value: currentDestination }));
  }, [currentDestination]);

  useEffect(() => {
    dispatch(getAllPropertyTypes({ value: "" }));
  }, [currentDestination]);

  const [allCurrentProperties, setAllCurrentProperties] = useState<any[]>([]);

  const [currentPropertyFilters, setCurrentPropertyFilters] = useState<any[]>(
    []
  );
  const [currentPriceFilers, setCurrentPriceFilers] = useState<any[]>([]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 4;
  const currentItems = allCurrentProperties.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allCurrentProperties?.length / 4);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * 4) % allCurrentProperties.length;
    setItemOffset(newOffset);
  };

  const handlePropertyNameChange = () => {};

  const updatePropertiesListings = () => {
    let tempFilteredListing: any[] = [];
    let tempFilters: any[] = [];

    if (
      selectedPropertyType &&
      selectedPropertyType?.length > 0 &&
      selectedPrice &&
      selectedPrice?.length > 0
    ) {
      selectedPropertyType.forEach((filter: any) => {
        tempFilters.push(filter.property_type_name);
      });
      let tempMinPrices: any[] = [];
      let tempMaxprices: any[] = [];
      selectedPrice.forEach((filter: any) => {
        tempMinPrices.push(filter.min);
        tempMaxprices.push(filter.max);
      });
      const max = Math.max(...tempMinPrices);
      const min = Math.min(...tempMaxprices);
      allProperties?.properties_details.forEach((property: any) => {
        if (
          tempFilters.includes(property.property_type_name) &&
          Number(property.price_per_day) > min &&
          Number(property.price_per_day) < max
        ) {
          tempFilteredListing.push(property);
        }
      });
      setAllCurrentProperties(tempFilteredListing);
    } else if (selectedPropertyType && selectedPropertyType?.length > 0) {
      selectedPropertyType.forEach((filter: any) => {
        tempFilters.push(filter.property_type_name);
      });
      allProperties?.properties_details.forEach((property: any) => {
        if (tempFilters.includes(property.property_type_name)) {
          tempFilteredListing.push(property);
        }
      });
      setAllCurrentProperties(tempFilteredListing);
    } else if (selectedPrice && selectedPrice?.length > 0) {
      let tempMinPrices: any[] = [];
      let tempMaxprices: any[] = [];
      selectedPrice.forEach((filter: any) => {
        tempMinPrices.push(filter.min);
        tempMaxprices.push(filter.max);
      });
      const min = Math.min(...tempMinPrices);
      const max = Math.max(...tempMaxprices);
      allProperties?.properties_details.forEach((property: any) => {
        if (
          Number(property.price_per_day) > min &&
          Number(property.price_per_day) < max
        ) {
          tempFilteredListing.push(property);
        }
      });
      setAllCurrentProperties(tempFilteredListing);
    } else {
      allProperties?.properties_details
        ? setAllCurrentProperties(allProperties?.properties_details)
        : setAllCurrentProperties([]);
    }
  };

  useEffect(() => {
    if (selectedPropertyType) updatePropertiesListings();
  }, [selectedPropertyType]);

  useEffect(() => {
    if (selectedPrice) updatePropertiesListings();
  }, [selectedPrice]);

  useEffect(() => {
    if (allProperties?.properties_details) updatePropertiesListings();
  }, [allProperties]);

  return (
    <Grid sx={{}}>
      <Grid sx={{ mx: "10%", minHeight: "1000px" }}>
        <SearchListing />
        <Spinner loading={propertyLoading} />
        <Grid
          container
          xs={12}
          sx={{
            m: 3,
            gap: 3,
            minWidth: "1300px",
          }}
        >
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              minWidth: "220px",
            }}
          >
            <Grid
              sx={{
                border: "1px solid grey",
                borderRa1ius: "3px",
              }}
            >
              <PropertyFilter
                title={"Property Types"}
                selections={allPropertyTypes?.all_property_types}
              />
              <PropertyFilter title={"Prices"} selections={prices} />
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{}}>
            <Grid item xs={12} sx={{}}>
              {allCurrentProperties?.length} Stayes
            </Grid>
            <Grid xs={12} sx={{ minWidth: "800px" }}>
              {currentItems.map((property_detail: object) => {
                return (
                  <>
                    <SearchCard propertyDetails={property_detail} />
                    <Divider />
                  </>
                );
              })}
            </Grid>

            <Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel=">>"
                onPageChange={handlePageClick}
                // pageRangeDisplayed={4}
                pageCount={pageCount}
                previousLabel="<<"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Listings;
