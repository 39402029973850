import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../hooks/hooks";
import { getPropertiesDestination } from "../../../apis/listingsApis";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentDestination } from "../../../store/index";
import { useSelector } from "react-redux";

interface SearchListingProps {
  zindex?: number;
  marginBottom?: number;
}

interface DestinationCriteria {
  condition: string;
  value: any;
  label: string;
}

const SearchListing = (prop: SearchListingProps) => {
  const { zindex, marginBottom } = prop;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentDestination } = useSelector((state: any) => state.listings);

  // stores the value of the selected destination
  const [selectedDestination, setSelectedDestination] = useState<any>(null);

  // stores the value of the list of active countries where we operate
  const [activeCountries, setActiveCountries] = useState<DestinationCriteria[]>(
    []
  );
  // stores the list of actives states from the database
  const [activeStates, setActiveStates] = useState<DestinationCriteria[]>([]);
  // stores the list of active cites from the database
  const [activeCities, setActiveCities] = useState<DestinationCriteria[]>([]);

  const [destinations, setDestinations] = useState<DestinationCriteria[]>([]);

  const searchStayInitialValues = {
    destination: "Anywhere",
    value: null,
    guests: 2,
    checkIn: new Date(),
    checkOut: new Date(),
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);

  const validateSearchStay: any = Yup.object({
    destination: Yup.string(),
    guests: Yup.number().integer().min(1).max(10).required(),
    checkIn: Yup.date().min(today, "Please choose future date"),
    checkOut: Yup.date().when(
      "checkIn",
      (checkIn, schema) => checkIn && schema.min(checkIn)
    ),
    // .required("Required"),
  });

  const SearchStayForm = useFormik({
    enableReinitialize: false,
    initialValues: searchStayInitialValues,
    validationSchema: validateSearchStay,
    onSubmit: (values) => {
      dispatch(setCurrentDestination(values));
      if (!location.pathname.includes("/listings")) navigate("/listings");
    },
  });

  const setDestinationList = async () => {
    const allDestination = await getPropertiesDestination();

    let countries: string[] = [];
    let states_names: string[] = [];
    let cities: string[] = [];
    let tempCountriesList: any[] = [];
    let tempStatesList: any[] = [];
    let tempCitiesList: any[] = [];
    let tempDestinationList: any[] = [];
    if (allDestination?.properties_destination) {
      allDestination?.properties_destination.forEach((destination: any) => {
        let country = destination.country_name;
        let country_code = destination.iso2;
        let country_id = destination.country_id;
        let state_name = destination.state_name ? destination.state_name : "";
        let state_code = destination.state_code ? destination.state_code : "";
        let state_id = destination.state_id ? destination.state_id : "";
        let city = destination.city_name;

        if (!countries.includes(country)) {
          tempCountriesList.push({
            condition: "country",
            value: {
              country: country,
              country_code: country_code,
              country_id: country_id,
            },
            country_code: country_code,
            label: country,
          });
          countries.push(country);
        }
        if (state_name !== "") {
          if (!states_names.includes(state_name)) {
            tempStatesList.push({
              condition: "state",
              value: {
                state_name: state_name,
                state_code: state_code,
                state_id: state_id,
              },
              state_code: state_code,
              label:
                state_name.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) =>
                  letter.toUpperCase()
                ) +
                ", " +
                country_code.toUpperCase(),
            });
            states_names.push(state_name);
          }
        }

        if (!cities.includes([city, state_code, country].join(", "))) {
          tempCitiesList.push({
            condition: "city",
            value: destination,
            other_label:
              state_name !== ""
                ? [
                    city.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) =>
                      letter.toUpperCase()
                    ),
                    state_code.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter.toUpperCase()
                    ),
                    country_code,
                  ].join(", ")
                : [
                    city.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) =>
                      letter.toUpperCase()
                    ),
                    country_code,
                  ].join(", "),
            label:
              state_name !== ""
                ? [
                    city.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) =>
                      letter.toUpperCase()
                    ),
                    state_name.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter: string) => letter.toUpperCase()
                    ),
                    country_code,
                  ].join(", ")
                : [
                    city.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) =>
                      letter.toUpperCase()
                    ),
                    country,
                  ].join(", "),
          });
          cities.push([city, state_code, country].join(", "));
        }
      });
      setActiveCountries(tempCountriesList);
      const filteredCountries = tempCountriesList.slice(0, 5);
      setActiveStates(tempStatesList);
      const filteredStates = tempStatesList.slice(0, 10);
      setActiveCities(tempCitiesList);
      const filteredCities = tempCitiesList.slice(0, 20);

      tempDestinationList = [
        ...[{ condition: "Anywhere", value: "Anywhere", label: "Anywhere" }],
        ...filteredCities,
        ...filteredStates,
        ...filteredCountries,
      ];

      setDestinations(tempDestinationList);
    }
  };
  const setCheckInCheckOut = () => {
    console.log(
      "outside setCheckInCheckOut currentDestination",
      currentDestination
    );

    if (currentDestination && currentDestination?.destination !== "Anywhere") {
      setSelectedDestination(currentDestination?.value);
    } else {
      setSelectedDestination({
        condition: "Anywhere",
        value: "Anywhere",
        label: "Anywhere",
      });
    }
    SearchStayForm.setFieldValue("guests", currentDestination?.guests);
    SearchStayForm.setFieldValue("checkIn", dayjs(currentDestination?.checkIn));
    SearchStayForm.setFieldValue(
      "checkOut",
      dayjs(currentDestination?.checkOut)
    );
  };

  useEffect(() => {
    setDestinationList();
    setCheckInCheckOut();
  }, []);

  useEffect(() => {}, [currentDestination]);

  const updateFilterOptions = (filterValue: any) => {
    setSelectedDestination(filterValue);
    SearchStayForm.setFieldValue(
      "destination",
      filterValue === null ? "" : filterValue.label
    );
    SearchStayForm.setFieldValue(
      "value",
      filterValue === null ? null : filterValue
    );
    // dispatch(
    //   setCurrentDestination({
    //     destination: filterValue === null ? "" : filterValue.label,
    //     value: filterValue === null ? null : filterValue,
    //   })
    // );
  };

  const handleDestinationChange = (filterValue: string) => {
    if (!filterValue || filterValue === "") {
      setSelectedDestination(null);
      setDestinations([
        ...[{ condition: "Anywhere", value: "Anywhere", label: "Anywhere" }],
        ...activeCountries.slice(0, 5),
        ...activeStates.slice(0, 10),
        ...activeCities.slice(0, 20),
      ]);
    } else {
      let tempCountriesList: any[] = [];
      let tempStatesList: any[] = [];
      let tempCitiesList: any[] = [];

      activeCountries.forEach((country) => {
        if (
          country.value?.country
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          country.value?.country_code
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        ) {
          tempCountriesList.push(country);
        }
      });
      activeStates.forEach((state) => {
        if (
          state.value?.state_name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          state.value?.state_code
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        ) {
          tempStatesList.push(state);
        }
      });

      activeCities.forEach((city) => {
        if (
          city.value?.city_name
            .toLowerCase()
            ?.includes(filterValue.toLowerCase()) ||
          city?.label.toLowerCase()?.includes(filterValue.toLowerCase())
        ) {
          tempCitiesList.push(city);
        }
      });

      console.log("...tempCitiesList.slice(0, 20)", [
        { condition: "Anywhere", value: "Anywhere", label: "Anywhere" },
        ...tempCountriesList.slice(0, 5),
        ...tempStatesList.slice(0, 5),
        ...tempCitiesList.slice(0, 20),
      ]);

      setDestinations([
        { condition: "Anywhere", value: "Anywhere", label: "Anywhere" },
        ...tempCountriesList.slice(0, 5),
        ...tempStatesList.slice(0, 5),
        ...tempCitiesList.slice(0, 20),
      ]);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) =>
      `${option.label} ${option.country_code} ${option.state_code} ${option.other_label}`,
  });

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: 3,
        alignItems: "center",
      }}
      // xs={8}
    >
      <Grid
        item
        xs={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          zIndex: zindex ? zindex : null,
          marginBottom: marginBottom ? marginBottom : null,
        }}
      >
        <Paper
          sx={{
            zIndex: zindex ? zindex : null,
            marginBottom: marginBottom ? marginBottom : null,
            width: "100%",
            p: 1,
          }}
          elevation={2}
        >
          <form onSubmit={SearchStayForm.handleSubmit}>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={4} sx={{ paddingRight: "10px" }}>
                <Typography>Destination</Typography>
                <Autocomplete
                  disablePortal
                  options={destinations}
                  onChange={(_, option) => updateFilterOptions(option)}
                  onInputChange={(_, value) => handleDestinationChange(value)}
                  getOptionLabel={(option) => option.label}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="destination"
                      name="destination"
                      error={
                        SearchStayForm.touched.destination &&
                        Boolean(SearchStayForm.errors.destination)
                      }
                    />
                  )}
                  value={selectedDestination}
                />
              </Grid>
              <Grid item xs={1} sx={{ paddingRight: "10px" }}>
                <Typography>Guests</Typography>
                <Select
                  id="guests"
                  name="guests"
                  value={SearchStayForm.values.guests}
                  onChange={(event) => {
                    console.log("value", event.target.value);
                    SearchStayForm.setFieldValue("guests", event.target.value);
                    // dispatch(
                    //   setCurrentDestination({ guests: event.target.value })
                    // );
                  }}
                  error={
                    SearchStayForm.touched.guests &&
                    Boolean(SearchStayForm.errors.guests)
                  }
                  placeholder={"Guests"}
                  sx={{ width: "100%" }}
                >
                  {Array.from({ length: 10 }, (v, k) => k + 1).map((key) => {
                    return <MenuItem value={key}>{key}</MenuItem>;
                  })}
                </Select>
              </Grid>
              <Grid item xs={3} sx={{ paddingRight: "10px" }}>
                <Typography>Check In</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkIn"
                    value={dayjs(SearchStayForm.values.checkIn)}
                    onChange={(value) => {
                      SearchStayForm.setFieldValue("checkIn", value);
                      // dispatch(
                      //   setCurrentDestination({ checkIn: value })
                      // );
                    }}
                    disablePast
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} sx={{ paddingRight: "10px" }}>
                <Typography>Check Out</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="checkOut"
                    value={dayjs(SearchStayForm.values.checkOut)}
                    onChange={(value) => {
                      SearchStayForm.setFieldValue("checkOut", value);
                      // dispatch(
                      //   setCurrentDestination({ checkOut: value })
                      // );
                    }}
                    disablePast
                    views={["year", "month", "day"]}
                    minDate={dayjs(SearchStayForm.values.checkIn)}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <IconButton
                  type="submit"
                  sx={{
                    backgroundColor: "blue",
                    width: 60,
                    height: 60,
                    "&:hover, &.Mui-focusVisible": {
                      backgroundColor: "blue",
                    },
                  }}
                  aria-label="search"
                  color="primary"
                  size={"large"}
                >
                  <SearchIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SearchListing;
