import React, { useContext, useEffect, useState } from "react";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Cards from "react-credit-cards-2";
import { Focused } from "react-credit-cards";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
  validatCard,
} from "../../../resources/payments";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import dayjs from "dayjs";
import { processPayment } from "../../../store/thunks/paymentThunk";

interface PaymentDialogProps {
  open: boolean;
  handleClose: () => void;
  stayPrice: string;
  cleaningFees: string;
  totalPrice: string;
  nights: string;
  taxes: string;
}

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    open,
    handleClose,
    stayPrice,
    cleaningFees,
    totalPrice,
    nights,
    taxes,
  } = props;
  const dispatch = useAppDispatch();

  const { selectedPropertyDetails, currentDestination } = useAppSelector(
    (state: any) => state.listings
  );
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  console.log("PaymentDialog selectedPropertyDetails", selectedPropertyDetails);
  console.log("PaymentDialog currentDestination", currentDestination);
  console.log("PaymentDialog userInfo", userInfo);
  console.log("PaymentDialog userInfo", userInfo);

  const stripePromise = loadStripe(
    "pk_test_51NO2UgEC8YRyVLJi8TjlK60HOfzNYsZ1iDEBwDytWFqED5NKq3ot7WcNJn8chykMUkYUVgrIjD2hUuDGuqFhtZf2009do8353y"
  );
  const [submited, setSubmitted] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [address, setAddress] = useState<any>({});
  const [state, setState] = useState<any>({
    cardNumber: "",
    cardExpiration: "",
    cardCvc: "",
    cardName: "",
    cardIssuer: "",
    focus: "" as Focused,
    zipCode: "",
  });

  const handleInputFocus = (evt: { target: { name: any } }) => {
    setState((prev: any) => ({ ...prev, focus: evt.target.name }));
  };

  const handleCallback = ({ issuer }: { issuer: any }, isValid: boolean) => {
    console.log("handleCallback", issuer);

    if (isValid) {
      setState((prev: any) => ({ ...prev, cardIssuer: issuer }));
    }
  };

  const handleInputChange = (evt: any) => {
    let value: string = "";
    if (evt.target.name === "cardNumber") {
      value = formatCreditCardNumber(evt.target.value);
    } else if (evt.target.name === "cardExpiration") {
      value = formatExpirationDate(evt.target.value);
    } else if (evt.target.name === "cardCvc") {
      value = formatCVC(evt.target.value);
    }
    setState((prev: any) => ({ ...prev, [evt.target.name]: value }));
    setSubmitted(false);
  };

  const cardInfoVerification = () => {
    console.log("cardInfoVerification state", state);

    const cardErros = validatCard(state);

    console.log("cardInfoVerification cardErros", cardErros);
    setErrors(cardErros);

    if (cardErros.isValid) {
      return true;
    }
    return false;
  };

  const confirmPay = () => {
    setSubmitted(true);
    const check = cardInfoVerification();
    console.log("errors", errors);
    console.log("userInfo", userInfo);

    if (check === false) return;
    console.log("submit payment");

    const paymentPaylod = {
      user_id: userInfo.user_id,
      email: userInfo.email,
      property_name: selectedPropertyDetails.property_name,
      stripe_customer_id: userInfo.stripe_customer_id
        ? userInfo.stripe_customer_id
        : "",
      property_id: selectedPropertyDetails.property_id,
      hostfully_id: selectedPropertyDetails.hostfully_property_id,
      check_in: currentDestination?.checkIn,
      check_out: currentDestination?.checkOut,
      payment_total: totalPrice,
      cardInfo: state,
      cardAddres: address,
    };

    console.log("paymentPaylod", paymentPaylod);
    dispatch(processPayment({ paymentInfo: paymentPaylod }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth={true}
      style={{ minWidth: "1220px" }}
    >
      <Grid container style={{ backgroundColor: "blue" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Confirmation & Payment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={6} style={{}}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Your Trip
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                margin: "5px 0px 5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6} sx={{ padding: "0px 0px 0px 10px" }}>
                <TextField
                  label="Dates"
                  value={
                    dayjs(currentDestination?.checkIn).format("ddd MMM DD") +
                    " - " +
                    dayjs(currentDestination?.checkOut).format("ddd MMM DD")
                  }
                  disabled
                  size="small"
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Guests"
                  value={currentDestination?.guests}
                  disabled
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Billing
              </Typography>
              <Grid container item xs={12} style={{}}>
                <Grid container item xs={6} style={{}}>
                  <Cards
                    number={state.cardNumber}
                    expiry={state.cardExpiration}
                    cvc={state.cardCvc}
                    name={state.cardName}
                    focused={state.focus}
                    callback={handleCallback}
                  />
                </Grid>
                <Grid container item xs={6} style={{ paddingLeft: "10px" }}>
                  <Grid item xs={12} sx={{ padding: "5px" }}>
                    <TextField
                      type="tel"
                      name="cardNumber"
                      placeholder="Card Number"
                      style={{ width: "100%" }}
                      inputProps={{
                        pattern: "[d| ]{16,22}",
                      }}
                      required
                      value={state.cardNumber}
                      onChange={(e) => handleInputChange(e)}
                      onFocus={handleInputFocus}
                      error={submited && !errors?.cardNumber}
                      size="small"
                      label="Card Number"
                    />
                  </Grid>
                  <Grid container item xs={12} style={{}}>
                    <Grid container item xs={6} sx={{ padding: "5px" }}>
                      <TextField
                        type="tel"
                        name="cardExpiration"
                        className="form-control"
                        placeholder="Valid Thru"
                        style={{ width: "100%" }}
                        inputProps={{
                          pattern: "dd/dd",
                        }}
                        required
                        value={state.cardExpiration}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        error={submited && !errors?.cardExpiration}
                        size="small"
                        label="Expiration"
                      />
                    </Grid>
                    <Grid container item xs={6} sx={{ padding: "5px" }}>
                      <TextField
                        type="tel"
                        name="cardCvc"
                        className="form-control"
                        placeholder="CVC"
                        style={{ width: "100%" }}
                        inputProps={{
                          pattern: "d{3,4}",
                        }}
                        required
                        value={state.cardCvc}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        error={submited && !errors?.cardCvc}
                        size="small"
                        label="Security Code"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    type="hidden"
                    name="cardIssuer"
                    value={state.cardIssuer}
                  />
                </Grid>
              </Grid>
            </Grid>
            {submited && !errors?.isValid && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Typography style={{ color: "red" }}>
                  {errors.message}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ padding: "5px", margin: "10px 0px 0px 0px" }}
            >
              <Elements stripe={stripePromise}>
                <AddressElement
                  className={"text-sm text-gray-700 border rounded"}
                  options={{ mode: "billing" }}
                  onChange={(event) => {
                    // const tempAddress: any = event.value;
                    console.log("address", event.value);
                    setAddress(event.value);
                    setState((prev: any) => ({
                      ...prev,
                      cardName: event.value.name ? event.value.name : "",
                      zipCode: event.value.address.postal_code
                        ? event.value.address.postal_code
                        : "",
                    }));
                  }}
                />
              </Elements>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ margin: 2 }} elevation={3}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 2,
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${
                      selectedPropertyDetails?.property_images_url.split(",")[0]
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: 2,
                  }}
                ></Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 0px 0px 5px",
                  }}
                >
                  <Grid item>
                    <Typography sx={{ fontSize: 12 }}>
                      {selectedPropertyDetails.property_type_label} in{" "}
                      {selectedPropertyDetails.city}
                      {", "}
                      {selectedPropertyDetails.state
                        ? selectedPropertyDetails.state
                        : selectedPropertyDetails.country}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {selectedPropertyDetails.property_name}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography sx={{}}>
                        {selectedPropertyDetails.guests} guests *{" "}
                        {selectedPropertyDetails.bedrooms} beds *{" "}
                        {selectedPropertyDetails.bathrooms} bathrooms
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <StarIcon color="primary" sx={{}} />
                      {selectedPropertyDetails.review
                        ? selectedPropertyDetails.review
                        : 5.0}{" "}
                      (325 reviews)
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", margin: "10px 0px 10px 0px" }}
                  >
                    Price Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>$86 x {nights} nights</Grid>
                  <Grid>$ {stayPrice}</Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>Entire Stay Cleaning Fee</Grid>
                  <Grid>${cleaningFees}</Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>Taxes</Grid>
                  <Grid>$ {taxes}</Grid>
                </Grid>
                <Divider sx={{ width: "100%", margin: "0px 0px 5px 0px" }}>
                  {" "}
                </Divider>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid sx={{ fontWeight: "bold" }}>Total (USD)</Grid>
                  <Grid sx={{ fontWeight: "bold" }}>$ {totalPrice}</Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Free cancelation until 3:00 PM on July 15, More infor
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          Edit Trip
        </Button>
        <Button variant="outlined" onClick={() => confirmPay()}>
          Confirn & Pay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PaymentDialog;
