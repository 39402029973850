import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import home from "./../../assets/listings/home.jpg";
import home1 from "./../../assets/home-2.jpeg";
import home3 from "./../../assets/home-3.jpeg";
import home4 from "./../../assets/home-4.jpg";
import DisplayImage from "../../components/reusableComponents/displayCard/displayCard";
import SearchListing from "../../components/reusableComponents/searchListings/searchListings";

import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/commonComponents/footer/footer";

const Hone = () => {
  const { loading, error, userInfo, loggedIn } = useSelector(
    (state: any) => state.authentication
  );

  const dispatch = useDispatch();
  return (
    <Grid>
      <Grid sx={{ mx: "10%", justifyContent: "center" }}>
        <SearchListing zindex={2} marginBottom={-3} />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            backgroundImage: `url(${home})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "600px",
            zIndex: 1,
          }}
        >
          <Grid sx={{ display: "flex", m: 2 }}>
            <Typography
              sx={{ fontWeight: "bold", color: "white" }}
              variant="h1"
            >
              Awaken to a different world
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "left",
            margin: "5% 0px 2% 0px",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            STAYS PICKED BY YOU
          </Typography>
        </Grid>
        <Grid container spacing={5} sx={{ marginBottom: "5%" }}>
          <DisplayImage
            image={home1}
            alignment={false}
            title={"Amsterdam"}
            description={"2,345 properties * 746 miles away"}
            containerHeight={"500px"}
            containerSize={3}
            detailDirection={true}
          />
          <DisplayImage
            image={home3}
            alignment={false}
            title={"New York"}
            description={"2,345 properties * 746 miles away"}
            containerHeight={"500px"}
            containerSize={3}
            detailDirection={true}
          />
          <DisplayImage
            image={home3}
            alignment={false}
            title={"Maldives"}
            description={"2,345 properties * 746 miles away"}
            containerHeight={"500px"}
            containerSize={3}
            detailDirection={true}
          />
          <DisplayImage
            image={home4}
            alignment={false}
            title={"Bhuthan"}
            description={"2,345 properties * 746 miles away"}
            containerHeight={"500px"}
            containerSize={3}
            detailDirection={true}
          />
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={3} item xs={10} sx={{}}>
            <DisplayImage
              image={home4}
              alignment={true}
              title={"Outdoor getaways"}
              description={"131 stays"}
              containerHeight={"500px"}
              containerSize={4}
            />
            <Grid container item xs={4}>
              <DisplayImage
                image={home4}
                alignment={true}
                title={"Unique Destinations"}
                description={"131 stays"}
                containerHeight={"230px"}
                containerSize={12}
              />
              <DisplayImage
                image={home4}
                alignment={true}
                title={"Entire Homes"}
                description={"131 stays"}
                containerHeight={"230px"}
                containerSize={12}
              />
            </Grid>
            <DisplayImage
              image={home4}
              alignment={true}
              title={"Pet's allowed"}
              description={"131 stays"}
              containerHeight={"500px"}
              containerSize={4}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            m: 5,
          }}
        >
          <Typography>Simple, Smooth, Secure</Typography>
          <Typography sx={{ fontWeight: "bold" }} variant="h3">
            How it work
          </Typography>
        </Grid>
        <Grid container spacing={5} sx={{ marginBottom: "5%" }}>
          <DisplayImage
            alignment={false}
            title={"Owner"}
            description={
              "List your properties & earn with a worry free mindset. Once you list with us we do the rest"
            }
            // containerHeight={"220px"}
            imageHeight={"100"}
            containerSize={3}
            iconType={"owner"}
            detailPosition={true}
            detailDirection={true}
          />
          <DisplayImage
            alignment={false}
            title={"Renter"}
            description={
              "Find what you need. Search by location or keyword. Filter by price, availability, or custom filters. View photos and listing details."
            }
            // containerHeight={"220px"}
            imageHeight={"100"}
            containerSize={3}
            iconType={"person"}
            detailDirection={true}
            detailPosition={true}
          />
          <DisplayImage
            alignment={false}
            title={"Secure Payments"}
            description={
              "Pay with a credit card. We hold your money until you've arrived at your stay! Owner's are paid after you check-in"
            }
            // containerHeight={"220px"}
            imageHeight={"100"}
            containerSize={3}
            iconType={"payment"}
            detailDirection={true}
            detailPosition={true}
          />
          <DisplayImage
            alignment={false}
            title={"Less Fee's"}
            description={
              "Simply less fees all around. Since we manage the property there are no hidden services fees, and no more cleaning fees"
            }
            // containerHeight={"220px"}
            imageHeight={"100"}
            containerSize={3}
            iconType={"fee"}
            detailDirection={true}
            detailPosition={true}
          />
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            m: 5,
          }}
        >
          <Button
            sx={{
              border: "1px solid blue",
              borderRadius: "30px",
              width: "200px",
            }}
          >
            Learn More
          </Button>
        </Grid>
        <Grid container spacing={5} sx={{ marginBottom: "5%" }}>
          <DisplayImage
            image={home4}
            alignment={true}
            title={"Adventure path for your trip"}
            description={"Experiences"}
            containerHeight={"500px"}
            containerSize={6}
            detailLocation={true}
          />
          <DisplayImage
            image={home4}
            alignment={true}
            title={"Instangram worthy #picture-escape stayes"}
            description={"Book your stay"}
            containerHeight={"500px"}
            containerSize={6}
            detailLocation={true}
          />
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Hone;
